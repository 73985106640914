<template>
  <div id="samplingRecord">
    <el-dialog
      :title="samplingRecordFormTitle"
      width="1200px"
      :visible.sync="samplingRecordDialogVisible"
      :close-on-click-modal="false"
      @close="samplingRecordDialogClose"
    >
      <el-form
        ref="samplingRecordFormRef"
        :model="samplingRecordForm"
        :rules="samplingRecordFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="取样日期" prop="samplingDate">
              <el-date-picker
                v-model="samplingRecordForm.samplingDate"
                placeholder="请选择日期"
                type="date"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="物料名称" prop="productName">
              <el-input v-model="samplingRecordForm.productName" placeholder="请输入物料名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="物料编号" prop="productNo">
              <el-input v-model="samplingRecordForm.productNo" placeholder="请输入物料编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="samplingRecordForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="samplingRecordForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="送检单位" prop="inspectionUnit">
              <el-input v-model="samplingRecordForm.inspectionUnit" placeholder="请输入送检单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="samplingRecordForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="取样量" prop="sampleSize">
              <el-input v-model="samplingRecordForm.sampleSize" placeholder="请输入取样量" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="samplingRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="samplingRecordFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="取样日期">
        <el-date-picker v-model="searchForm.samplingDate" placeholder="请选择取样日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="samplingRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column label="取样日期">
        <template slot-scope="scope">
          <span v-if="scope.row.samplingDate">{{ scope.row.samplingDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productName" label="物料名称（样品名）" />
      <el-table-column prop="productNo" label="物料编号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="inspectionUnit" label="送检单位" />
      <el-table-column prop="sampler" label="取样人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="sampleSize" label="取样量" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="samplingRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addSamplingRecord, deleteSamplingRecord, updateSamplingRecord, selectSamplingRecordInfo, selectSamplingRecordList } from '@/api/quality/samplingRecord'

export default {
  data () {
    return {
      samplingRecordDialogVisible: false,
      samplingRecordFormTitle: '',
      samplingRecordForm: {
        id: '',
        samplingDate: '',
        productName: '',
        productNo: '',
        spec: '',
        batchNo: '',
        inspectionUnit: '',
        remarks: '',
        sampleSize: ''
      },
      samplingRecordFormRules: {
        samplingDate: [{ required: true, message: '取样日期不能为空', trigger: ['blur', 'change']}]
      },
      samplingRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        samplingDate: ''
      }
    }
  },
  created () {
    selectSamplingRecordList(this.searchForm).then(res => {
      this.samplingRecordPage = res
    })
  },
  methods: {
    samplingRecordDialogClose () {
      this.$refs.samplingRecordFormRef.resetFields()
      this.samplingRecordForm.id = ''
    },
    samplingRecordFormSubmit () {
      if (this.samplingRecordFormTitle === '取样记录') {
        this.samplingRecordDialogVisible = false
        return
      }
      this.$refs.samplingRecordFormRef.validate(async valid => {
        if (valid) {
          if (this.samplingRecordFormTitle === '新增取样记录') {
            await addSamplingRecord(this.samplingRecordForm)
          } else if (this.samplingRecordFormTitle === '修改取样记录') {
            await updateSamplingRecord(this.samplingRecordForm)
          }
          this.samplingRecordPage = await selectSamplingRecordList(this.searchForm)
          this.samplingRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.samplingRecordFormTitle = '新增取样记录'
      this.samplingRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSamplingRecord(row.id)
        if (this.samplingRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.samplingRecordPage = await selectSamplingRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.samplingRecordFormTitle = '修改取样记录'
      this.samplingRecordDialogVisible = true
      this.selectSamplingRecordById(row.id)
    },
    handleInfo (index, row) {
      this.samplingRecordFormTitle = '取样记录详情'
      this.samplingRecordDialogVisible = true
      this.selectSamplingRecordById(row.id)
    },
    selectSamplingRecordById (id) {
      selectSamplingRecordInfo(id).then(res => {
        this.samplingRecordForm.id = res.id
        this.samplingRecordForm.samplingDate = res.samplingDate
        this.samplingRecordForm.productName = res.productName
        this.samplingRecordForm.productNo = res.productNo
        this.samplingRecordForm.spec = res.spec
        this.samplingRecordForm.batchNo = res.batchNo
        this.samplingRecordForm.inspectionUnit = res.inspectionUnit
        this.samplingRecordForm.remarks = res.remarks
        this.samplingRecordForm.sampleSize = res.sampleSize
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSamplingRecordList(this.searchForm).then(res => {
        this.samplingRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSamplingRecordList(this.searchForm).then(res => {
        this.samplingRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSamplingRecordList(this.searchForm).then(res => {
        this.samplingRecordPage = res
      })
    }
  }
}
</script>

<style>
</style>
